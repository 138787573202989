import Layout from 'components/Layout'
import Accelerator from 'components/states/ohio/Accelerator'
import DigitalSolutionsAccess from 'components/states/ohio/DigitalSolutionsAccess'
import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

export default function NewYork() {
	const data = useStaticQuery<ToolkitQuery>(query)

	const { wideReadingElementary, toolkitsElementary } = data

	return (
		<Layout showHeader={false} showFooter={false}>
			<Accelerator
				title="NYC Public Schools Literacy Intervention Program"
				gradeLevel="K-5"
				level="elementary"
				wideReading={wideReadingElementary}
				toolkits={toolkitsElementary}
			/>
			<DigitalSolutionsAccess
				credentials={{ 'K-5': { username: 'nycinterventionk5', password: 'BH6XLarwU_fd' }, '6-12': { username: 'nycintervention612', password: 'WkXL@Ccj3bHa' } }}
				gradeLevel="K-5"
			/>
		</Layout>
	)
}

export interface AcceleratorBasketQueryEdge {
	edges: { node: { title: string; gatsbyImageData: IGatsbyImageData } }[]
}

export interface AcceleratorToolkitQueryEdge {
	edges: { node: { name: string; images: { gatsbyImageData: IGatsbyImageData }[] } }[]
}

interface ToolkitQuery {
	wideReadingElementary: AcceleratorBasketQueryEdge
	toolkitsElementary: AcceleratorToolkitQueryEdge
}

const query = graphql`
	query {
		wideReadingElementary: allContentfulAsset(
			filter: {
				title: {
					in: [
						"Wide Reading | 1G Basket 1"
						"Wide Reading | 2G Basket 1"
						"Wide Reading | 1B Basket 1"
						"Wide Reading | 2B Basket 1"
						"Wide Reading | 1R Basket 1"
						"Wide Reading | 2R Basket 1"
						"Wide Reading | Wt Basket 1"
						"Wide Reading | Bk Basket 1"
					]
				}
			}
		) {
			edges {
				node {
					title
					gatsbyImageData
				}
			}
		}
		toolkitsElementary: allContentfulProduct(
			filter: {
				name: {
					in: [
						"IRLA Foundational Skills Toolkit 1G"
						"IRLA Foundational Skills Toolkit 2G"
						"IRLA Foundational Skills Toolkit 1B"
						"IRLA Foundational Skills Toolkit 2B"
						"IRLA Foundational Skills Toolkit 1R"
						"IRLA Foundational Skills Toolkit 2R"
						"IRLA Foundational Skills Toolkit 2R"
						"IRLA Toolkit Wt"
						"IRLA Toolkit Bk"
					]
				}
			}
		) {
			edges {
				node {
					name
					images {
						gatsbyImageData
					}
				}
			}
		}
	}
`
